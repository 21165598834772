import React, {useState} from "react";
import {BsFillTelephoneFill} from 'react-icons/bs';
import {MdEmail} from 'react-icons/md';
import {BsTwitter, BsFacebook} from 'react-icons/bs';
import {AiFillInstagram} from 'react-icons/ai';
import styles from "./contact.module.css";
import {Button} from "@chakra-ui/react";
import {SOCIAL_FACEBOOK, SOCIAL_INSTAGRAM, SOCIAL_TWITTER} from "@eazy2biz-ui/promo-package";

export const Contact = () => {
    const [showForm, setShowForm] = useState(false);

    const handleContactClick = (url: string) => {
        window.open(url, '_blank');
    };

    return (
        <>
            <div className={styles.get_in_touch}>
              <div className={styles.contact_heading}>
                <div>
                  <h2 className={styles.contact_head}>Get in touch with Us</h2>
                </div>
                <div className={styles.contact_us_box}>
                  <h2 className={styles.contact_us_heading}>Talk to a member of our Sales team</h2>
                  <p className={styles.contact_us_para}>We will help you find the right products and pricing for your business.</p>
                    <Button
                        onClick={() => setShowForm(!showForm)}
                        as={'a'}
                        fontSize={'xl'}
                        fontWeight={700}
                        color={'white'}
                        bg={'#2A50E3'}
                        _hover={{
                            bg: ' #176cf4',
                            color: 'white',
                        }}>
                        Contact Us
                    </Button>
                </div>
              </div>
            </div>
            {
                showForm && (
                    <div className={styles.form_container}>
                        <form className={styles.form}>
                            <div className={styles.close_btn} onClick={() => setShowForm(false)}>X</div>
                            <div>
                              <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                required
                                className={styles.input}
                              />
                              <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                required
                                className={styles.input}
                              />
                            </div>
                            <div>
                              <input
                                type="text"
                                name="phone"
                                placeholder="Phone"
                                required
                                className={styles.input}
                              />
                              <input
                                type="text"
                                name="company"
                                placeholder="Company Name"
                                required
                                className={styles.input}
                              />
                            </div>
                            <textarea
                              name="message"
                              placeholder="Message"
                              required
                              className={`${styles.input} ${styles.input_msg}`}
                            />
                            <div className={styles.parent_container}>
                                <button className={styles.submit_btn}>Submit</button>
                            </div>
                        </form>
                    </div>
            )}
            <div className={styles.get_in_touch}>
                <div className={styles.contact_Details}>
                    <div className={styles.contact_box}>
                      <BsFillTelephoneFill className={styles.icon}/>	+91-9355923751 / 52
                    </div>
                    <div className={styles.contact_box}>
                      <MdEmail className={styles.icon}/> contact@eazy2biz.com
                    </div>
                    <div className={styles.contact_box} onClick={() => handleContactClick(SOCIAL_TWITTER)}>
                        <BsTwitter className={styles.icon}/>
                        / eazy2biz
                    </div>
                    <div className={styles.contact_box} onClick={() => handleContactClick(SOCIAL_FACEBOOK)}>
                        <BsFacebook className={styles.icon}/>
                        / eazy2biz
                    </div>
                    <div className={styles.contact_box} onClick={() => handleContactClick(SOCIAL_INSTAGRAM)}>
                        <AiFillInstagram className={styles.icon}/>
                        / eazy2biz
                    </div>
                </div>
           </div>
        </>
    );
};
