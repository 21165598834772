import {RoutesEnum} from "@eazy2biz-ui/promo-package";
import {Homepage} from "./components/homepage/Homepage";
import { About } from "./components/About/About";
import {TermsPage} from "./components/security/termsofServices/TermsPage";
import {PrivacyPage} from "./components/security/privacy/PrivacyPage";
import { Contact } from "./components/contact/contact";
import { Pricing } from "./components/pricing/Pricing";

export type RouteConfig = {
    url: string;
    component: any;
}

const RoutesConfig: RouteConfig[] = [
    {
        url: RoutesEnum.APP_HOMEPAGE,
        component: Homepage
    },
    {
        url: RoutesEnum.APP_ABOUT,
        component: About
    },
    {
        url: RoutesEnum.APP_TERMSOFSERVICES,
        component: TermsPage
    },
    {
        url: RoutesEnum.APP_PRIVACY,
        component: PrivacyPage
    },
    {
        url: RoutesEnum.APP_PRICING,
        component: Pricing
    },
    {
        url: RoutesEnum.APP_CONTACT,
        component: Contact,
    }
];

export default RoutesConfig;
