import React from 'react';
import {Child} from "@eazy2biz-ui/promo-components";
import styles from  './boxes.module.css';
import classNames from 'classnames';

const Span: React.FC<React.PropsWithChildren<{}>> = (props) => {
  return (
    <span style={{ color: 'purple' }}>
      {props.children}
    </span>
  );
};

const CompanyBox: React.FC<React.PropsWithChildren<{}>> = (props) => {
  return (
    <div className={styles.companyBox}>
              {props.children}
    </div>
  );
};


// Smallbox component

interface SmallboxProps {
  iconSquareColor: string;
  iconColor: string,
  heading: string;
  color: string;
  children: React.ReactNode;
  className?: string;
  height: string;
  icon: any;
}

const Smallbox: React.FC<SmallboxProps> = (props) => {
  return (
    <div className={classNames(styles.smallBox, props.className)} style={{backgroundColor: props.color, height: props.height}}>
        <Child className={styles.imageWrapper}>
          <div  className={styles.iconSquare} style={{ backgroundColor: props.iconSquareColor }}>
            <img src={props.icon} className={styles.icon} />
          </div>
        </Child>
        <Child className={styles.textWrapper}>
          <div>
            <h3>{props.heading}</h3>
          </div>
          <span style={{color: props.iconColor}}>{props.children}</span>
        </Child>
    </div>
  );
};

// Box component

interface BoxProps {
  color:string;
  boxheight:string;
  children: React.ReactNode;
}

const Box: React.FC<BoxProps> = (props) => {
  return (
    <div className={styles.box} style={{backgroundColor: props.color,
      height: props.boxheight,
    }}>
      {props.children}
    </div>
  );
};

export { Span, CompanyBox, Smallbox, Box };
