import React from "react";
import { Link } from 'react-router-dom';
import { BsFacebook } from 'react-icons/bs';
import { BsTwitter } from 'react-icons/bs';
import { BsInstagram } from 'react-icons/bs';
import GooglePlay from '../../../../assets/GooglePlay.svg'
import AppStore from '../../../../assets/AppStore.svg'
import Eazy2Biz from '../../../../assets/eazy2biz_logo.svg'
import styles from "./footer.module.css";
import {
  copyright, PLAY_STORE_URL,
  reserved,
  RoutesEnum,
  SOCIAL_FACEBOOK,
  SOCIAL_INSTAGRAM,
  SOCIAL_TWITTER
} from "@eazy2biz-ui/promo-package";

/**
 * Homepage for application
 * @constructor
 */
export const Footer = () => {
  return (
    <footer className={styles.background}>
      <div className={styles.footer_container}>
        <div className={styles.footerMainContent}>
          <div className={styles.column}>
            <img className={styles.logo} src={Eazy2Biz} alt="LOGO"></img><br/>
            <ul>
              <h6 className={styles.copy_right}>{copyright}</h6>
              <h6 className={styles.rights}>{reserved}</h6>
              <div className={styles.social_media}>
                <div className={styles.facebook}><a rel={'external'} href={SOCIAL_FACEBOOK} target={"_blank"}><BsFacebook /></a></div>
                <div className={styles.twitter}><a rel={'external'} href={SOCIAL_TWITTER} target={"_blank"}><BsTwitter /></a></div>
                <div className={styles.instagram}><a rel={'external'} href={SOCIAL_INSTAGRAM} target={"_blank"}><BsInstagram /></a></div>
              </div>
            </ul>
          </div>
          <div className={styles.column}>
            <h3 className={`${styles.head} ${styles.hide}`}>Use Cases</h3>
            <ul className={styles.hide}>
              <li><Link to="/item-1">Digital transformation</Link></li>
            </ul>
          </div>
          <div className={styles.column}>
            <h3 className={`${styles.head} ${styles.hide}`}>Expand & Learn</h3>
            <ul className={styles.hide}>
              <li><Link to="/item-1">Platform Overview</Link></li>
            </ul>
          </div>
          <div className={styles.column}>
            <h3 className={styles.head}>Security & privacy</h3>
            <ul>
              {/*<li><Link to="/item-1">Compliance</Link></li>*/}
              <li><Link to={RoutesEnum.APP_TERMSOFSERVICES}><span className={styles.footerLinkHover}>Terms of services</span></Link></li>
              <li><Link to={RoutesEnum.APP_PRIVACY}><span className={styles.footerLinkHover}>Privacy Policy</span></Link></li>
              {/*<li><Link to="/item-4">GDPR Policy</Link></li>*/}
              {/*<li><Link to="/item-4">Responsible Disclosure</Link></li>*/}
            </ul>
          </div>
        </div>
        <div className={styles.appStoreLogos}>
          <img className={styles.storeLogo} src={GooglePlay} onClick={() => window.open(PLAY_STORE_URL, '_blank')}/>
          <img className={styles.storeLogo} src={AppStore}/>
        </div>
      </div>
    </footer>
  );
};
