import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styles from "./Pricing.module.css";
import {APP_CONSOLE_URL} from "@eazy2biz-ui/promo-package";


interface PricingBoxProps {
  planName: string;
  description: string;
  price: string;
  info?: string;
  get: string;
  lists: string[];
  link: string;
}

export const PricingBox: React.FC<PricingBoxProps> = (props: PricingBoxProps) => {

  const { planName, description, price, info, get, link, lists } = props;

  return (
    <div className={styles.boxContainer}>
      <div className={styles.box}>
        <h2 className={styles.box_title}>{planName}</h2>
        <p className={styles.box_description}>{description}</p>
        <p className={styles.box_price}>{price}</p>
        <p className={styles.box_info}>{info}</p>
        <a target={'_blank'} href={link}>
        <button className={styles.box_get}>
          {get}
        </button>
        </a>
        <ul className={styles.box_list}>
          {lists.map((listItem, index) => (
            <li key={index} className={styles.box_listItem} style={{ display: 'flex', alignItems: 'center' }}>
              {index === 1 ? <br /> : null}
              {listItem}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
