import React from "react";
import { Container } from "@eazy2biz-ui/promo-components";
import { Child } from "@eazy2biz-ui/promo-components";
import { Span, Smallbox } from '@eazy2biz-ui/promo-components';
import "./services.css"
import {ServiceConfig, serviceConfigs} from "./ServicesConfig";
import styles from './services.module.css';

export const Services = () => {

  const renderServiceBox = (service: ServiceConfig) => {
    return(
        <Smallbox
            iconSquareColor="transparent"
            iconColor={service.iconColor}
            heading=""
            height="165px"
            color={service.color}
            icon={service.icon}
        >
          <p className={"info"}>{service.data}</p>
        </Smallbox>
    );
  };
  return (
    <>
      <div className={"gray-background"}>
        <Container width1="70%" width2="30%" marginLeft="7%">
          <Child>
            <div className="space2">
              <h2 className="gray-background-heading">Automate your business your way</h2>
              <h2 className="gray-background-heading">with <Span>100x advantage</Span></h2>
              <br />
              <h5 className="light-heading">10x faster at 1/10th the cost</h5>
            </div>
          </Child>
        </Container>
        <div className={styles.servicesBox}>
          {serviceConfigs.map(renderServiceBox)}
        </div>
      </div>
      {/*<div className="gradient">*/}
      {/*  <h3 className="servicesHeading">You're in good company</h3>*/}

      {/*  <Box color="white" boxheight="300px">*/}
      {/*    <CompanyBox>Element 1</CompanyBox>*/}
      {/*    <CompanyBox>Element 2</CompanyBox>*/}
      {/*    <CompanyBox>Element 3</CompanyBox>*/}
      {/*    <CompanyBox>Element 4</CompanyBox>*/}
      {/*    <CompanyBox>Element 5</CompanyBox>*/}
      {/*    <CompanyBox>Element 6</CompanyBox>*/}
      {/*    <CompanyBox>Element 7</CompanyBox>*/}
      {/*    <CompanyBox>Element 8</CompanyBox>*/}
      {/*  </Box>*/}
      {/*</div>*/}
    </>

  );
};
