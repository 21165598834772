import React from 'react';
import styles from"./containerChild.module.css";


interface ContainerProps {
  className?: string;
  children?: React.ReactNode;
  width1: string;
  width2: string;
  marginLeft: string;
}

export const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  return (
    <div className={`${styles.container} ${props.className}`} style={{ gridTemplateColumns: `${props.width1} ${props.width2}` ,marginLeft:`${props.marginLeft}`}}>
      {props.children}
    </div>
  );
}


