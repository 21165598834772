export const HOMEPAGE_STRING = {
    MAIN_HEADING: 'Accelerate Inclusive Digital Transformation',
    SECONDARY_HEADING: 'Empowers you to build your own no-code workflow to automate & manage your business ' +
        'processes with a click of a button!\n',
    FEATURE_OPTIONS_HEADING: 'Realise the power of automation!'
};

export const HOMEPAGE_BANNER_STRINGS = {
    TAG_LINE: 'Automate your way!',
    NO_CODE: 'Experience the No-Code thru workflows',
};

export const HOMEPAGE_FEATURE_OPTIONS = {
    WORKFLOW: {
        NAME: 'Workflows',
        HEADER: "Automate your business process and focus on work that matters",
        TEXT: "eazy2biz provides a visual workflow builder to build your own process via a drag and drop interface which suits your needs. " +
            "It also provides hundreds of templates to get you started.",
    },
    COMMUNICATION: {
        NAME: 'Communication',
        HEADER: "Communicate and collaborate with your clients, vendor, colleagues, team",
        TEXT: "Automated actions like reminders, tasks, emails, messages, notifications, whatsapp, slack can be" +
            "added to your workflow, so you can service your clients quickly, correctly and reliably.",
    },
    INTEGRATIONS: {
        NAME: 'Integrations',
        HEADER: "Connect workflows with applications to reduce mundane tasks",
        TEXT: "Automatically working with various other apps like CRM, accounting software will save time" +
            "and effort for your employees.",
    },
    DATABASE: {
        NAME: 'Databases',
        HEADER:"Manage your data at the same place",
        TEXT: "The back office spends too much time in maintaining books and lists of customers, expenses, products etc. " +
            "Smart automation reduces that overhead so employees can rather focus on the work that adds the most value."
    },
    REPORTS: {
        NAME: 'Reports',
        HEADER: "Automatically report on every step in the process",
        TEXT: "Process analytics shouldn’t require you to pull data. When work happens, data happens. " +
            "With eazy2biz, your team does the work and the reports build themselves. " +
            "Use these reports for insights about your operations and sales.",
    }
};

export const HOMEPAGE_SERVICES = {
    WORKFLOWS: 'Automate your processes via drag-and-drop no-code visual studio',
    INTEGRATIONS: 'Communicate, collaborate thru an unified platform',
    FORMS: 'Build your own forms with a solid low-code form builder',
    TASKS: 'Out-of-the-box task management tools and reminders',
    TEMPLATES: 'Accelerate using our gallery of 400+ pre-built templates',
    DATABASE: 'Use the same application to maintain details of clients, vendors and generate reports',
};

export const HOMEPAGE_MASTER_BENEFITS = {
    CUSTOM: {
        HEADER:"Automate your way!",
        TEXT: "eazy2biz believe that it is your business and you know better therefore automation should also be your way. " +
            "We provide you custom builder to build your workflows, forms, tasks and also reports.",
    },
    EFFICIENCY: {
        HEADER:"Execute your daily tasks with heroic efficiency and precision",
        TEXT: "Now you control the workflow, so you can fulfill your tasks quickly, correctly and reliably. This reduces human error and " +
            "enables better tracking and accountability.",
    },
    AUTOMATE: {
        HEADER: "Automate busywork and focus on what really matters",
        TEXT: "Businesses spend too much time with repeated tasks which can be eliminated by smart automation and it reduces that overhead" +
            " therefore owners & employees can instead focus on increasing their business."
    },
    REPORTS: {
        HEADER: "Automatically report on every step in the process",
        TEXT: "Analytics shouldn’t require you to scratch your books. When work happens, data happens. " +
            "With eazy2biz, your team does the work and the reports build themselves and more importantly according to you.",
    }
};
