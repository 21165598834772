import React from "react";
import styles from "./navbar.module.css";
import eazyLogo from '../../../../assets/eazy2biz_logo.svg';
import {APP_CONSOLE_URL, DOCS_URL, RoutesEnum} from "@eazy2biz-ui/promo-package";
import {
  Box,
  Button,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Link,
  Stack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import {AiOutlineClose, AiOutlineDown, AiOutlineMenu} from "react-icons/ai"

export const Navbar: React.FC = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box>
      <Flex
        bg={useColorModeValue('#212429', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}>
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <AiOutlineClose color='white' /> : <AiOutlineMenu color='white' />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: 5 }} justify={{ base: 'start', md: 'start' }}>
          <Link
            p={0}
            href={RoutesEnum.APP_HOMEPAGE}
          >
            {<img src={eazyLogo} width={'120px'} className="" alt='logo' />}
          </Link>

          <Flex display={{ base: 'none', md: 'flex' }} ml={10} mt={1}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={6}>
          <Button
            as={'a'}
            color={'white'}
            fontSize={'m'}
            fontWeight={400}
            variant={'link'}
            _hover={{
              color: '#54585e',
            }}>
              <a target={'_blank'} href={APP_CONSOLE_URL}>Log In</a>
          </Button>
          <Button
              as={'a'}
              color={'white'}
              fontSize={'m'}
              fontWeight={400}
              variant={'link'}
              _hover={{
                color: '#54585e',
              }}>
            <a target={'_blank'} href={DOCS_URL}>Documentation</a>
          </Button>
          <a target={'_blank'} href={APP_CONSOLE_URL}>
            <Button
              as={'a'}
              fontSize={'m'}
              fontWeight={400}
              color={'white'}
              bg={'#2A50E3'}
              _hover={{
                bg: ' #176cf4',
                color: 'white',
              }}>
              Try eazy2biz for free
            </Button>
          </a>

        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue('white', 'gray.200');
  const linkHoverColor = useColorModeValue('#54585e', 'white');

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Link
            p={2}
            href={navItem.href ?? '#'}
            fontSize={'m'}
            fontWeight={400}
            color={linkColor}
            _hover={{
              textDecoration: 'none',
              color: linkHoverColor,
            }}>
            {navItem.label}
          </Link>
        </Box>
      ))}
    </Stack>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue('#F1F3F5', 'gray.800')}
      pb={4}
      pt={4}
      borderBottomRadius={10}
      display={{ md: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, href, children }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <div key={label} className={styles.botColor}>
        <Box borderBottomColor={'white'}>
          <Link
            p={2}
            href={href ?? '#'}
            fontSize={'m'}
            fontWeight={500}
            color={'#212429'}
            _hover={{
              textDecoration: 'none',
              color: '#54585e',
            }}>
            {label}
          </Link>
        </Box>
      </div>
      {children && (
        <Icon
          as={AiOutlineDown}
          transition={'all .25s ease-in-out'}
          transform={isOpen ? 'rotate(180deg)' : ''}
          w={6}
          h={6}
        />
      )}
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: 'Pricing',
    href: RoutesEnum.APP_PRICING,

  },
  {
    label: 'About Us',
    href: RoutesEnum.APP_ABOUT,
  },
  {
    label: 'Contact Us',
    href: RoutesEnum.APP_CONTACT,
  },
];

