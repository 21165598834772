import React, { useState } from 'react';
import styles from "./option.module.css"
import {featureOptions, FeatureOptionsConfig} from "./FeatureOptionsConfig";
import classNames from "classnames";
import {HOMEPAGE_STRING} from "@eazy2biz-ui/promo-package";

/**
 * Feature Options
 * @constructor
 */
export const OptionsSection: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<FeatureOptionsConfig>(featureOptions[0]);

  const renderOptionButtons = (featureOption: FeatureOptionsConfig) => {
    return (
        <button
            key={featureOption.id}
            className={classNames(styles.option, {[styles.optionSelected]: selectedOption.id === featureOption.id})}
            onClick={() => setSelectedOption(featureOption)}
        >{featureOption.name}</button>
    );
  };

  const renderFeatureDetail = () => {
    return (
      <div className={styles.featureDetail}>
        <div className={classNames(styles.info)}>
          <h2 className={styles.headinfo}>{selectedOption.header}</h2>
          <p className={styles.para}>{selectedOption.description}</p>
        </div>
        <img className={styles.optionImage} src={selectedOption.image} alt={selectedOption.name} />
      </div>
    );
  };

  return (
    <section className={styles.optionsSection}>
      <h3 className={styles.optionHeading}>{HOMEPAGE_STRING.FEATURE_OPTIONS_HEADING}</h3>
      <div className={styles.options}>
        {featureOptions.map(renderOptionButtons)}
      </div>
        <hr className={styles.dividerOption}/>
      {renderFeatureDetail()}
    </section>
  );
};
