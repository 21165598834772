import {Environments} from "@eazy2biz/common-util";

export const CURRENT_ENVIRONMENT = () => process.env.REACT_APP_ENV ?? Environments.QAL.toString();

export const configs = {
  [Environments.DEV.toString()]: {
    cacheExpiry: {
      hardExpiry: 86400, // 1 day in secs
      softExpiry: 3600 // 1 hours in secs
    },
    uiHostUrl: 'http://localhost:3000',
    applicationUrl: 'http://app.qal.eazy2biz.com',
    communicationEmail: 'test.eazy2biz@gmail.com',
  },
  [Environments.DEV2.toString()]: {
    cacheExpiry: {
      hardExpiry: 86400, // 1 day in secs
      softExpiry: 3600 // 1 hours in secs
    },
    uiHostUrl: 'http://localhost:3000',
    applicationUrl: 'http://app.qal.eazy2biz.com',
    communicationEmail: 'test.eazy2biz@gmail.com',
  },
  [Environments.QAL.toString()]: {
    cacheExpiry: {
      hardExpiry: 86400, // 1 day in secs
      softExpiry: 3600 // 1 hours in secs
    },
    uiHostUrl: 'http://qal.eazy2biz.com',
    applicationUrl: 'http://app.qal.eazy2biz.com',
    communicationEmail: 'test.eazy2biz@gmail.com',
  },
  [Environments.E2E.toString()]: {
    cacheExpiry: {
      hardExpiry: 86400, // 1 day in secs
      softExpiry: 3600 // 1 hours in secs
    },
    uiHostUrl: 'http://e2e.eazy2biz.com',
    applicationUrl: 'http://app.e2e.eazy2biz.com',
    communicationEmail: 'test.eazy2biz@gmail.com',
  },
  [Environments.PROD.toString()]: {
    cacheExpiry: {
      hardExpiry: 86400, // 1 day in secs
      softExpiry: 3600 // 1 hours in secs
    },
    uiHostUrl: 'https://eazy2biz.com',
    applicationUrl: 'https://app.eazy2biz.com',
    communicationEmail: 'no-reply@eazy2biz.com',
  },
};
