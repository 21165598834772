import React from 'react';
import  styles from "./Button.module.css"

interface ButtonProps {
  backgroundColor: string;
  width: string;
  textColor: string;
  borderColor:string;
  children: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = (props) => {
  return (
    <button className={styles.btn} style={{
      backgroundColor: props.backgroundColor,
      border: '2px props.borderColor',
      color: props.textColor,
      width: props.width,
    }}>
      {props.children}
    </button>
  );
};


