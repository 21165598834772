import React from 'react';
import  styles from './ComparisonTable.module.css';
import { TiTick } from 'react-icons/ti';
import { RxCross2 } from 'react-icons/rx';

  interface PlanInformation {
    'Free Trial': string;
    Basic: string;
    Enterprise: string;
    [key: string]: string;
  }


  interface Feature {
    featureName: string;
    featuredesc?: string;
    planInformation: PlanInformation;
  }

  interface ComparisonTableProps {
    features: Feature[];
  }

  export const ComparisonTable: React.FC<ComparisonTableProps> = ({ features }) => {
    const planNames = Object.keys(features[0].planInformation);

    return (
      <table className={styles.comparison_table}>
       <tbody>
          <tr>
            <th>Features</th>
            {planNames.map(planName => (
              <th key={planName}>{planName}</th>
            ))}
          </tr>

          {features.map(feature => (
            <tr key={feature.featureName}>
              <td>
                <h5 className={styles.features}>{feature.featureName}</h5>
                <p className={styles.features_desc}>{feature.featuredesc}</p>
              </td>

              {planNames.map(planName => (
                <td key={planName}>
                  {feature.planInformation[planName] === "::Yes::" ? (
                    <span className={styles.checkmark}><TiTick/></span>
                  ) : feature.planInformation[planName] === "::No::" ? (
                    <span className={styles.crossmark}><RxCross2/></span>
                  ) : (
                    feature.planInformation[planName]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
