const termsHeading = "Terms of Service";
const infoParagraph = 'This is an Agreement (“Agreement”) between Protego technologies LLP, herein (“Protego”), having an office and place of business at Protego technologies LLP, C-209, Bock C, Sector 48, Noida, UP, India - 201301 and the entity agreeing to the terms herein (“Participant”, “You”, “you” or “Customer”).';

const descriptionHeading = `1. Description of Service`;
const descriptionPara = `Protego provides a software as a web service, also referred to as cloud service, named Protego (hereinafter referred as “Service” or “Services”). The Service is offered and provided subject to the terms and conditions of this Agreement. The Customer shall connect to the Service using any internet browser or mobile application supported by the Service. The Customer is responsible for obtaining access to the internet and the equipment necessary to access the service.`;

const modificationHeading = `2. Modification of Terms of Service`;
const modificationPara = `Protego reserves the right to update and change the Terms of Service from time to time; an updated version will be published on our website at terms of service. Any new features that augment or enhance the current Service, including the release of new tools and resources, shall be subject to this Terms of Service. You may terminate your use of the Services if the Terms are modified in a manner that substantially affects your rights in connection with use of the Services. Your continued use of the Service after any such changes shall constitute your consent to such changes. You can review the most current version of the Terms of Service at any time at terms of service.`;

const restrictionsHeading = `3. Restrictions on Use`;
const restrictionsPara = `In addition to all other terms and conditions of this Agreement, you shall not: (i) transfer or otherwise make available to any third party the Services; (ii) provide any service based on the Services without prior written permission; (iii) use the Services for spamming and/or other illegal purposes; or (iv) reverse engineer or access the Service in order to (a) build a competitive product or service, (b) build a product using similar ideas, features, functions or graphics of the Service, or (c) copy any ideas, features, functions or graphics of the Service.`;

const paymentHeading = `4. Payment, Refund, and Subscription Terms`;
const paymentPara = `A free trial is offered without the need for a credit card.
                To continue using the Services after the free trial period, you must begin a paid subscription which requires a valid credit card. Enterprise customers may request a paper contract that includes alternate billing arrangements including purchase orders.
                The Service is billed on a monthly and/or annual basis. In both cases, the amount billed is non-refundable. There will be no refunds or credits for partial months of service.
                Each term shall automatically renew for subsequent periods of the same length as the initial term unless either party gives the other written notice of termination at least thirty (30) days prior to expiration of the then-current term.
                Information on the subscription options and charges for all paid Services is available in the pricing page of our website.
                Protego reserves the right to change the subscription fee from time to time.
                In case of non-payment for any reason or any violation of these terms, Protego shall be entitled – without liability – to immediately bar Customer’s access to the Service.`;
const dataHeading = `5. Data ownership & Privacy`;
const dataPara = `Protego does not own any customer data. The Customer shall have the sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use any and all customer data.
                Protego provides an automatic data backup for all customer data. This backup solution is built on top of the world’s leading cloud hosting providers like Google, Inc., Amazon.com, Inc., and others. However, there might be rare occasions of data loss or failure to store or retrieve any data due to a defect in our software or the software used by the above-mentioned cloud service providers. In such cases, Protego will restore the last backup data in mutual consultation with the customer.
                Protego participates in and has certified its compliance with the EU-U.S. and Swiss-U.S. Privacy Shield Frameworks as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of Personal Data (as defined in the Protego Data Processing Agreement) transferred from the European Economic Area and/or Switzerland, as applicable, to the U.S. For additional detail on our commitments with respect to the EU-U.S. and Swiss-U.S. Privacy Shield Frameworks, see our Privacy Policy. To the extent that Protego processes any Personal Data as part of Customer Data that is subject to the General Data Protection Regulation (the “GDPR”), on Customer’s behalf, in the provision of the services hereunder, the terms of the Protego Data Processing Agreement , which are hereby incorporated by reference, shall apply. For customers that are located in the European Union or the European Economic Area, the Standard Contractual Clauses adopted by the European Commission, attached to the Data Processing Agreement, with Protego, Inc., which provide adequate safeguards with respect to the personal data processed by us under this Agreement and pursuant to the provisions of our Data Processing Agreement apply. You acknowledge in all cases that Protego acts as the data processor of Customer Data and you are the data controller of Customer Data under applicable data protection regulations in the European Union and European Economic Area. The Customer will obtain and maintain any required consents necessary to permit the processing of Customer Data under this Agreement. If you are subject to the GDPR you understand that if you give an integration provider access to your Protego account, you serve as the data controller of such information and the integration provider serves as the data processor for the purposes of those data laws and regulations that apply to you. In no case are such integration providers are considered our sub-processors.
                Solely and only for the purpose of providing the services to you, with respect to Protego functionalities like backup, replication, high availability, caching for speed, performance, throughput and various other functional and non-functional capabilities, you grant Protego royalty-free, worldwide permission to access, copy, distribute, store, transmit, reformat, display and perform the content of your account.
                You hereby consent that, Protego may identify you as a Customer of the service (using your name and logo) and generally describe the products or services it provides to you in its promotional materials, presentations, media, press releases and proposals to other current and prospective customers.
                If a Customer does not renew the subscription for the Service, Protego shall provide all Customer Data on explicit written request from the customer in a generally accessible format within 30 days after the end of such term and thereafter may delete the customer account and all of the Customer Data. Please note that some information like billing and subscription may remain with us for accounting and legal reasons. Additionally, Customer Data may remain with us for the period of 90 days on our replication servers and/or high availability servers, beyond which it is completely deleted.`;

const confidentialHeading = `6. Confidentiality`;
const confidentialPara = `Confidential Information of the Customer shall mean all business and technological information of Customer and shall include the Customer Data. Confidential Information of Protego shall mean the Services other than the Customer Data. Confidential Information shall not include any information which is in the public domain (other than through a breach of this agreement), which is independently developed by the recipient or which is received by a third party not under restriction. The recipient will not disclose the Confidential Information, except to affiliates, employees, agents, professional advisors, or third party vendors who participate in the provision of the Services hereunder who need to know it and who have agreed to keep it confidential. The recipient will ensure that those people and entities use the received Confidential Information only to exercise rights and fulfill obligations under this Agreement, while using reasonable care to keep it confidential. The recipient may also disclose Confidential Information to the extent required by an order of a government entity of appropriate jurisdiction; provided that the recipient uses commercially reasonable efforts to promptly notify the other party of such disclosure before complying with such order.`;

const generalHeading = `7. General Account Terms`;
const generalPara = `Access to the Service is only available to the Customer and the Users to whom the Customer grants access, subject to the Customer making the applicable payments for the Service under this Agreement.
                Usernames and passwords are personal, and are to be considered part of Confidential Information of Customer. The Customer is at all times fully liable for all acts and omissions by Users whom the Customer has granted access and agrees to indemnify Protego for all claims and losses related to such acts and omissions.
                Customer may not use the Service for any illegal or unauthorized purpose. Customer must not, in the use of the Service, violate any laws in customer jurisdiction (including but not limited to copyright laws).
                Protego may make updates to the Service from time to time as per market demands.
                Customer is responsible for all taxes and Customer will pay Protego for the Services without any reduction for taxes. If Protego is obligated to collect or pay taxes, the taxes will be invoiced to Customer.
                Except as expressly set forth herein, this Agreement does not grant either party any rights, implied or otherwise, to the other’s content or any of the other’s intellectual property. As between the parties, Customer owns all Intellectual Property Rights in Customer Data and Protego owns all Intellectual Property Rights in the Service.
                When the Customer pays Protego via bank transfer, the necessary bank transfer charges should be borne by the Customer. For avoidance of doubt, the total license fee payable should be credited to our account without any deductions.`;


const warrantyHeading = `8. Warranty and Disclaimer`;
const warrantyPara = `Protego warrants that it will provide 99% uptime of the service failing which downtime credit will be provided for the time the service was not available.
                Protego agrees to defend, indemnify, and hold harmless Customer, its Affiliates, and their respective, officers, directors, employees, agents, representatives and contractors from and against any claims, actions, demands, liabilities and expenses, including, without limitation, damages, other monetary relief, expert fees, costs and reasonable legal fees, alleging or resulting from any claim that the Services infringe upon the intellectual property rights of whatever type or form of any third party.
                Protego is not responsible for any delays, delivery failures, or other damage resulting from limitations, delays, and other problems inherent in the use of the Customer provided internet and electronic communications.
                Except as otherwise provided herein, the Service is provided on an “AS IS” basis, without warranties of any kind, either express or implied, including, without limitation, implied warranties of title, merchantability, fitness for a particular purpose or non-infringement.
                Protego takes reasonable care for virus protection and other harmful components; however, given the nature of virus evolution, Protego makes no warranty that the service will be free of viruses or other harmful components.
                Protego makes no warranty that:
                the service will be error-free or uninterrupted (including, without limitation, interruptions that occur in the context of regularly scheduled maintenance); however, when such errors or interruptions occur, Protego will take reasonable efforts the rectify the errors and interruptions;
                any information or advice obtained by the Customer in connection with the usage of the Service for the fitment of its internal business purpose will be accurate or complete. Protego’s employees and consultants make a best case effort to provide the most relevant information to the customer but Protego would not be able to guaranteed its accuracy or fitment for purpose; that should be decided by the customer by taking into account other factors that Protego might not be privy to,
                the results of using the Service will meet Customer requirements.
                `;


const limitationHeading = `9. Limitation of Liability`;
const limitationPara = `IN NO EVENT SHALL PROTEGO BE LIABLE FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, OR OTHER LOSS OR DAMAGE WHATSOEVER OR FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, COMPUTER FAILURE, LOSS OF BUSINESS INFORMATION, OR OTHER LOSS ARISING OUT OF OR CAUSED BY YOUR USE OF OR INABILITY TO USE THE SERVICE, EVEN IF Protego HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH Protego RELATED TO ANY OF THE SERVICES SHALL BE TERMINATION OF SUCH SERVICE. IN NO EVENT SHALL PROTEGO’S ENTIRE LIABILITY TO YOU IN RESPECT OF ANY SERVICE, WHETHER DIRECT OR INDIRECT, EXCEED THE LAST 3 MONTHS FEES PAID BY YOU TOWARDS SUCH SERVICE`;

const privacyHeading = `10. Privacy Terms`;
const privacyPara = `At Protego, we respect our customers and users need for online privacy and protect any personal information that may be shared with us, in an appropriate manner. Protego’s practice in regards to the use of customer personal information is detailed in our Privacy Policy Page which you can find here .`;

const governingHeading = `11. Governing Law & Jurisdiction`;
const governingPara = 'This agreement shall be governed by the laws of the State of Uttar Pradesh, India';

const termsDate = '20 November 2022';
const termsLastUpdatedDate = '20 November 2022';

export const TERMS_STRINGS = {
    termsHeading,
    infoParagraph,
    descriptionHeading,
    descriptionPara,
    modificationHeading,
    modificationPara,
    restrictionsHeading,
    restrictionsPara,
    paymentHeading,
    paymentPara,
    dataHeading,
    dataPara,
    confidentialHeading,
    confidentialPara,
    generalHeading,
    generalPara,
    warrantyHeading,
    warrantyPara,
    limitationHeading,
    limitationPara,
    privacyHeading,
    privacyPara,
    governingHeading,
    governingPara,
    termsDate,
    termsLastUpdatedDate
};
