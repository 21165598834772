import React from "react";
import { SecurityPage } from "@eazy2biz-ui/promo-components";
import styles from "./terms.module.css";
import { Heading } from "@chakra-ui/react";
import {TERMS_STRINGS} from "@eazy2biz-ui/promo-package";

/**
 * Terms of Service Page.
 * @constructor
 */
export const TermsPage = () => {
    const renderContent = () => {
        return (
            <div>
                <p>{TERMS_STRINGS.infoParagraph}</p>
                <br/>
                <Heading mb={4} as='h1' size='lg'>{TERMS_STRINGS.descriptionHeading}</Heading>
                <p>{TERMS_STRINGS.descriptionPara}</p>
                <br/>
                <Heading mb={4} as='h1' size='lg'>{TERMS_STRINGS.modificationHeading}</Heading>
                <p>{TERMS_STRINGS.modificationPara}</p>
                <br/>
                <Heading mb={4} as='h1' size='lg'>{TERMS_STRINGS.restrictionsHeading}</Heading>
                <p>{TERMS_STRINGS.restrictionsPara}</p>
                <br/>
                <Heading mb={4} as='h1' size='lg'>{TERMS_STRINGS.paymentHeading}</Heading>
                <p>{TERMS_STRINGS.paymentPara}</p>
                <br/>
                <Heading mb={4} as='h1' size='lg'>{TERMS_STRINGS.dataHeading}</Heading>
                <p>{TERMS_STRINGS.dataPara}</p>
                <br/>
                <Heading mb={4} as='h1' size='lg'>{TERMS_STRINGS.confidentialHeading}</Heading>
                <p>{TERMS_STRINGS.confidentialPara}</p>
                <br/>
                <Heading mb={4} as='h1' size='lg'>{TERMS_STRINGS.generalHeading}</Heading>
                <p>{TERMS_STRINGS.generalPara}</p>
                <br/>
                <Heading mb={4} as='h1' size='lg'>{TERMS_STRINGS.warrantyHeading}</Heading>
                <p>{TERMS_STRINGS.warrantyPara}</p>
                <br/>
                <Heading mb={4} as='h1' size='lg'>{TERMS_STRINGS.limitationHeading}</Heading>
                <p>{TERMS_STRINGS.limitationPara}</p>
                <br/>
                <Heading mb={4} as='h1' size='lg'>{TERMS_STRINGS.privacyHeading}</Heading>
                <p>{TERMS_STRINGS.privacyPara}</p>
                <br/>
                <Heading mb={4} as='h1' size='lg'>{TERMS_STRINGS.governingHeading}</Heading>
                <p>{TERMS_STRINGS.governingPara}</p>
                <br/>
            </div>
        );
    };

    return (
        <>
            <div className={styles.termsSection}>
                <SecurityPage heading={TERMS_STRINGS.termsHeading} date={TERMS_STRINGS.termsDate} lastDate={TERMS_STRINGS.termsLastUpdatedDate}>
                    {renderContent()}
                </SecurityPage>
            </div>
        </>

    );
};
