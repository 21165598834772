import React, {useEffect} from "react";
import { Navbar } from "../navbar/Navbar";
import { Footer } from "../footer/Footer";

export const CoreLayoutComponent: (props: PropTypes)  => JSX.Element = (props) => {

    const {component: Component} = props;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [Component]);

    const renderNavBar = () => {
        return (
            <Navbar/>
        );
    };

    const renderFooter = () => {
        return (
            <Footer/>
        );
    };

    return (
        <>
            {renderNavBar()}
            {/*@ts-ignore*/}
            <Component {...props} />
            {renderFooter()}
        </>
    );
};

type PropTypes = {
    component: React.ReactElement;
};
