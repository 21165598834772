import {
    HOMEPAGE_SERVICES,
    FormIcon,
    ListIcon,
    IntegrationIcon,
    TemplateIcon,
    WorkflowIcon,
    TaskIcon
} from "@eazy2biz-ui/promo-package";

export interface ServiceConfig {
    id: string;
    data: string;
    icon: any;
    iconColor: string;
    color: string;
}

export const serviceConfigs: ServiceConfig[] = [
    {
        id: '1',
        data: HOMEPAGE_SERVICES.WORKFLOWS,
        icon: WorkflowIcon,
        iconColor: "#245AAF",
        color: "#f4f8ff",
    },
    {
        id: '2',
        data: HOMEPAGE_SERVICES.FORMS,
        icon: FormIcon,
        iconColor: "#B22485",
        color: "#FAECF9",
    },
    {
        id: '3',
        data: HOMEPAGE_SERVICES.TEMPLATES,
        icon: TemplateIcon,
        iconColor: "#E8671E",
        color: "#FCF7EC",
    },
    {
        id: '4',
        data: HOMEPAGE_SERVICES.TASKS,
        icon: TaskIcon,
        iconColor: "#6C9E5F",
        color: "#EAF9EB",

    },
    {
        id: '5',
        data: HOMEPAGE_SERVICES.INTEGRATIONS,
        icon: IntegrationIcon,
        iconColor: "#5853A5",
        color: "#F4F8FF",
    },
    {
        id: '6',
        data: HOMEPAGE_SERVICES.DATABASE,
        icon: ListIcon,
        iconColor: "black",
        color: "#F4F4FB",
    }
];
