export const ABOUT_STRINGS = {
    ABOUT_TITLE: 'About eazy2biz',
    DESCRIPTION: 'Empowering businesses thru automation and analytics',
    ABOUT_US_HEADER: 'eazy2biz empowers world-class managers to create and run efficient workflows',
    ABOUT_US_TEXT: 'eazy2biz optimizes your business processes one workflow at a time. Empowering every "doer" to organize and control their work in a single place, streamline and automate any process, without a single line of code.\n' +
        '            When "doers" have the tools they need to improve their work without relying on anyone else, there’s no limit to what they can accomplish. That’s why eazy2biz enhances agility, upgrades efficiency and delivers augmented customer value through optimization of workflows, granting higher quality outcomes.',
    SERVICE_CUSTOMER_HEADER: 'Serve your customers with heroic efficiency and precision',
    SERVICE_CUSTOMER_DESCRIPTION: 'Now you control the workflow, so you can fulfill customer requirements quickly, correctly and reliably.',
    BENEFITS_HEADER: 'Automate busywork and focus on work that matters',
    BENEFITS_DESCRIPTION: 'The back office spends too much time with repeated tasks. Smart automation reduces that overhead so ' +
        'employees can focus on the interesting work that adds the most value.',
};
