import React from "react";
import {SecurityPage} from "@eazy2biz-ui/promo-components";
import styles from "./privacy.module.css";
import { Heading } from "@chakra-ui/react";
import {PRIVACY_STRINGS} from "@eazy2biz-ui/promo-package";

/**
 * PrivacyPage page.
 * @constructor
 */
export const PrivacyPage = () => {
    const renderSubSections = () => {
        return (
            <div className={styles.childContainer}>
                <Heading  mb={4} as='h1' size='lg'>{PRIVACY_STRINGS.infoHeading}</Heading>
                <p>{PRIVACY_STRINGS.infoParagraph}</p>
                <br/>
                <Heading  mb={4} as='h1' size='lg'>{PRIVACY_STRINGS.infoCollectionHeading}</Heading>
                <p>{PRIVACY_STRINGS.infoCollectionPara}</p>
                <br/>
                <Heading  mb={4} as='h1' size='lg'>{PRIVACY_STRINGS.googleHeading}</Heading>
                <p>{PRIVACY_STRINGS.googlePara}</p>
                <br/>
                <Heading  mb={4} as='h1' size='lg'>{PRIVACY_STRINGS.cookiesHeading}</Heading>
                <p>{PRIVACY_STRINGS.cookiesPara}</p>
                <br/>
                <Heading  mb={4} as='h1' size='lg'>{PRIVACY_STRINGS.useHeading}</Heading>
                <p>{PRIVACY_STRINGS.usePara}</p>
                <br/>
                <Heading  mb={4} as='h1' size='lg'>{PRIVACY_STRINGS.transferDataHeading}</Heading>
                <p>{PRIVACY_STRINGS.transferDataPara}</p>
                <br/>
                <Heading  mb={4} as='h1' size='lg'>{PRIVACY_STRINGS.howSecureHeading}</Heading>
                <p>{PRIVACY_STRINGS.howSecurePara}</p>
                <br/>
                <Heading  mb={4} as='h1' size='lg'>{PRIVACY_STRINGS.retentionHeading}</Heading>
                <p>{PRIVACY_STRINGS.retentionPara}</p>
                <br/>
                <Heading  mb={4} as='h1' size='lg'>{PRIVACY_STRINGS.modificationHeading}</Heading>
                <p>{PRIVACY_STRINGS.modificationPara}</p>
                <br/>
            </div>
        );
    }

    return (
        <>
            <div className={styles.privacySection}>
                <SecurityPage
                    heading={PRIVACY_STRINGS.pageHeading}
                    date={PRIVACY_STRINGS.privacyAgreementDate}
                    lastDate={PRIVACY_STRINGS.privacyAgreementLastUpdatedDate}>
                    {renderSubSections()}
                </SecurityPage>
            </div>
        </>

    );
};
