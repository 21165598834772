export const copyright = "Copyright © Protego Technologies 2023";
export const reserved = "All Rights are Reserved";

//use cases
export const UseCasesHead = "Use Cases";
export const digital = "Digital transformation";
export const App = "App Development";
export const Workflow = "Workflow Management";
export const Citizen = "Citizen Developmet";
export const Procurement = "Procurement Cloud";
export const Project = "Project Management";

//expand and l
export const ExpandHead = "Expand & Learn";
export const Platform = "Platform Overview";
export const Customers = "Customers";
export const Testimonials = "Testimonials";
export const Partners = "Partners";
export const Academy = "Academy";
export const Documentation = "Documentation";
export const Languages = "Languages";

//security and privacy
export const SecurityHead = "Security & privacy";
export const Compliance = "Compliance";
export const Terms = "Terms of services";
export const Privacy = "PrivacyPage Policy";
export const GDPR = "GDPR Policy";
export const Responsible = "Responsible Disclosure";
