const pageHeading = "PrivacyPage Policy";
const infoHeading = "Information we collect:";
const infoParagraph = "For those users who provide requisite authorization during the account setup and login process, Protego Technologies collects information from enquiry forms, contacts forms, Google GSuite or Microsoft Office 365, and others, as applicable to provide better service to all users. This includes first name, last name, email address, phone numbers and email groups that users belong to. We store this personal information on Google Cloud Services. Our legal basis for the collection of user data is Art 6 (1) b) GDPR and Art. 6 (1) f) GDPR. Our legitimate interest is to optimize our product, increase user experience, enhance our customer support, marketing and improve our internal process efficiency.";
const infoCollectionHeading = "Information we collect as customers use our service.";
const infoCollectionPara = "Protego Technologies may collect specific information about how customers use our product. This include situations such as how a customer uses a specific feature and when a customer uses the product. The information is collected as data logs, images, or a logical sequence of images/videos to replay an issue encountered by a user. Our logging system automatically collects information such as the internet protocol address, browser type, browser language, referring URL, features accessed, errors generated, time zone, geo-location data, operating system information, and other such information that is transmitted in the header of the user’s HTTP request. This information is stored in log files. Protego Technologies uses these log files to analyze trends, administer, and improve the application. Our legal basis for the collection of user data is Art 6 (1) b) GDPR and Art. 6 (1) f) GDPR. Our legitimate interest is to optimize our product, increase user experience, enhance our customer support, and improve our internal process efficiency.";
const googleHeading = "Google user data";
const googlePara = "We use Google APIs for achieving the following:Google OAuth APIs are used to perform a Single Sign On through Google, while doing so we ensure that the email provided by the Google Auth server is a valid user of the Protego Technologies system.We use Google Drive APIs to retrieve file URLs and Ids of the files attached by the user into his/her Protego Technologies account. We also manage the permissions of these files through the drive APIs when the workflow on Protego Technologies attached to these files are on progress.";
const cookiesHeading = "Cookies";
const cookiesPara= "We use cookies on all our websites and software applications. Cookies are small text files that your browser saves on your computer and mobile device, which allow an analysis of your usage. Cookies contain information information about your device and your connection to the Internet, such as: IP address, operating system and browser used. We use our access to the information in the cookies to optimize our offering, to present appropriate content to customers easily, and to improve our advertising and marketing. This is done as follows: Most of the cookies we use are so-called session cookies, which are automatically deleted by your browser after the end of your visit to our website. Session cookies provide us with information about visitor behavior on our website, which we analyze. With the knowledge gained, we will make our website more user-friendly, place important content on the most popular pages and develop our marketing strategies according to the interests of our target audience. Other cookies are stored longer on your device and allow us to recognize you as a user during a subsequent visit to our website. We do not pass on information that we receive through our cookies to third parties. By using the “Incognito” or “Private” mode of your browser, or by means of adjusting the settings in your browser, you can prevent the installation of cookies. As a result, some of the facilitation of operating our website will no longer work. Nevertheless, you can use the website, login and all offers of Protego Technologies.com even without cookies. Our legal basis for the use of cookies is Art. 6 (1) b) GDPR and Art. 6 (1) f) GDPR. Our legitimate interest is to optimize our product, increase user experience, enhance our customer support and improve our internal process efficiency.";
const useHeading = "How we use the Information we collect";
const usePara ="Protego Technologies uses the above information for the following general purposes: service provisioning, billing, identification and authorization, service improvement, and research. When a customer contacts Protego Technologies, we may keep a record of customer communication to help solve any issues the customer might be facing.If you contact us by email or contact form, the information you provide will be stored for the purpose of processing the request and for possible follow-up questions. Please email us at privacy@Protego Technologies.com to assert your data protection rights. Please read the section entitled “Your rights to access, rectification, cancellation, limitation of processing, data portability and to withdraw consent”.Service Providers. We may provide access to or share your information with select third parties who perform services on our behalf. These third parties provide a variety of services to us, including without limitation billing, sales, marketing, provision of content and features, advertising, analytics, research, customer service, shipping and fulfilment, data storage, security, fraud prevention, payment processing, and legal services. The list of our third-party service providers can be found here: Data Processing AddendumComplying with our obligations: we may process your personal data to comply with our legal or regulatory requirements, where this is required by law. To continue using the Services after the free trial period, you must begin a paid subscription which requires a valid credit card. Enterprise customers may request a paper contract that includes alternative billing arrangements including purchase orders."
const transferDataHeading = "International Data Transfer";
const transferDataPara = "We may transfer information that we collect about you, including personal information, to affiliated entities, or to other third parties (as provided herein) across borders and from your country or jurisdiction to other countries or jurisdictions around the world. If you are located in the European Union or other regions with laws governing data collection and use that may differ from U.S. law, please note that you are transferring information, including personal information, to a country and jurisdiction that does not have the same data protection laws as your jurisdiction, and you consent to the transfer of information to the U.S. and the use and disclosure of information about you, including personal information, as described in this PrivacyPage Policy.";
const howSecureHeading = "How Secure is your personal data and information with us?";
const howSecurePara = `We work hard to protect Protego Technologies and our users from unauthorized access or unauthorized alteration, disclosure, or destruction of information we hold. In particular:
                    We encrypt our services using SSL.
                    We review our information collection, storage and processing practices, including physical security measures, to guard against unauthorized access to systems.
                    We restrict access to personal information to Protego Technologies employees, contractors and agents who need to know that information in order to process it for us, and who are subject to strict contractual confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.
                    We are also ISO 27001 Certified and we follow all the regulatory norms set by this standard. When we receive formal written complaints, we will contact the person who made the complaint to follow up. We work with the appropriate regulatory authorities, including local data protection authorities, to resolve any complaints regarding the transfer of personal data that we cannot resolve with our users directly.`;
const retentionHeading = `Retention, deletion or blocking of data`;
const retentionPara= `We adhere to the principles of purpose limitation and data minimisation. Therefore, we only store your personal data for as long as it is necessary to achieve the purposes mentioned here or as long as the many storage periods required by law. After discontinuation of the respective purpose or expiry of these deadlines, the corresponding data will be blocked or deleted in accordance with the statutory provisions. Our legal basis for the deletion or blocking of personal data is Art. 5 (1) b) GDPR and Art. 5 (1) c) GDPR.

Your rights of access, rectification, cancellation, limitation of processing, data portability and to withdraw consent
We will provide access to your personal data stored by us at any time. If you wish, you will receive it in a structured, commonly used and machine-readable format. We will be happy to correct, edit, limit, or delete your personal data at your request, provided that no statutory storage requirements are in conflict, or forward them to another responsible office. If your personal data stored with us has been processed incorrectly, out-of-date, or unlawfully, you can restrict its processing by us. Finally, you may change or revoke your consent to the processing of your personal information at any time with future effect. To contact us for any of these concerns, please email us at privacy@Protego Technologies.com. Our legal basis for the disclosure, correction, deletion, limitation of processing and data transmission is the respective request of the data subject.

You have the right to complain about the improper processing of your personal data (such as collection, storage, modification, transfer, deletion, etc.) by emailing us at
legal@eazy2biz.com.`;

const modificationHeading = `Modification of this Privacy Policy`;
const modificationPara = `We reserve the right to change this Privacy Policy from time to time to ensure that it complies with current legal requirements or to implement changes to our services in the Privacy Policy, such as when introducing new services. Your new visit will be subject to the new privacy policy.`;


const privacyAgreementDate = '20 November 2022';
const privacyAgreementLastUpdatedDate = '20 November 2022';

export const PRIVACY_STRINGS = {
    pageHeading,
    infoHeading,
    infoParagraph,
    infoCollectionHeading,
    infoCollectionPara,
    googleHeading,
    googlePara,
    cookiesHeading,
    cookiesPara,
    useHeading,
    usePara,
    transferDataHeading,
    transferDataPara,
    howSecureHeading,
    howSecurePara,
    retentionHeading,
    retentionPara,
    modificationHeading,
    modificationPara,
    privacyAgreementDate,
    privacyAgreementLastUpdatedDate
};
