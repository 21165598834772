import React from "react";
import { Child, Container } from "@eazy2biz-ui/promo-components";
import styles from "./About.module.css";
import {HeroComponent} from "@eazy2biz-ui/promo-components";
import {ABOUT_STRINGS, CustomImage, TaskImage} from "@eazy2biz-ui/promo-package";

/**
 * About Us Page.
 * @constructor
 */
export const About = () => {
    return (
        <>
            <div className={styles.blueBoxContainer}>
                <h3>{ABOUT_STRINGS.ABOUT_TITLE}</h3>
                <p>{ABOUT_STRINGS.DESCRIPTION}</p>
            </div>
            <Container className={styles.About_us_margin} width1="50%" width2="50%" marginLeft="10%">
                <Child>
                    <h3 className={styles.About_us_head}>{ABOUT_STRINGS.ABOUT_US_HEADER}</h3>
                </Child>
                <Child>
                    <p>{ABOUT_STRINGS.ABOUT_US_TEXT}</p>
                </Child>
            </Container>
            <HeroComponent
                heading={ABOUT_STRINGS.SERVICE_CUSTOMER_HEADER}
                para={ABOUT_STRINGS.SERVICE_CUSTOMER_DESCRIPTION}
                link="#"
                image={CustomImage}
                imgSide= "left"/>
            <HeroComponent
                heading={ABOUT_STRINGS.BENEFITS_HEADER}
                para={ABOUT_STRINGS.BENEFITS_DESCRIPTION}
                link="#"
                image={TaskImage}
                imgSide= "right"/>
        </>
    );
};
