import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import RoutesConfig from "./RoutesConfig";
import { CoreLayoutComponent } from './components/core/CoreLayoutComponent/CoreLayoutComponent';

const App = () => {
    return (
        <>
            <Routes>
                {RoutesConfig.map((route) => (
                    <Route  key={route.url} path={route.url} element={<CoreLayoutComponent component={route.component}/>} />
                ))}
            </Routes>
        </>
    );
}

export default App;
