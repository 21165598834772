import React from 'react';

interface ChildProps {
  className?: string;
  children?: React.ReactNode;
}

export const Child: React.FC<ChildProps> = (props: ChildProps) => {
  return (
    <div className={props.className}>
      {props.children}
    </div>
  );
}


