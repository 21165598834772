import React from "react";
import {Button, HeroComponent } from "@eazy2biz-ui/promo-components";
import { OptionsSection } from "./optionssection/optionSection";
import { Services } from "./services/Services";
import styles from "./homepage.module.css";
import {
    APP_CONSOLE_URL,
    HOMEPAGE_STRING,
    RoutesEnum,
    HomepageHeaderBKImage,
    HOMEPAGE_BANNER_STRINGS, Eazy2bizLogo
} from "@eazy2biz-ui/promo-package";
import {HomePageBenefits, homePageBenefits} from "./HomePageBenefits";

export const Homepage = () => {

    const renderMainHeader = () => {
        return (
            <div className={styles.homePageHeader} style={{backgroundImage: `url(${HomepageHeaderBKImage})`}}>
                <div className={styles.mainHeading}>
                    <h1 className={styles.heroHead}>{HOMEPAGE_STRING.MAIN_HEADING}</h1>
                </div>
                <h2 className={styles.subheading}>{HOMEPAGE_STRING.SECONDARY_HEADING}</h2>
                <div className={styles.buttons}>
                    <Button backgroundColor="#0565FF" textColor="#FFFFFF" borderColor="black" width="170px">
                        <a href={APP_CONSOLE_URL} target={'_blank'}>Get Started</a>
                    </Button>
                    <Button
                        backgroundColor="#FFf"
                        textColor="#000"
                        borderColor="#0565FF"
                        width="170px">
                        <a href={RoutesEnum.APP_CONTACT}>Book a Demo</a>
                    </Button>
                </div>
                {renderBanner()}
            </div>
        );
    };

    const renderBanner = () => {
        return (
            <div className={styles.blackBox}>
                <div>
                    <h4 className={styles.boxheading}>{HOMEPAGE_BANNER_STRINGS.TAG_LINE}</h4>
                    <h4 className={styles.boxSubHeading}>{HOMEPAGE_BANNER_STRINGS.NO_CODE}</h4>
                </div>
                <img className={styles.bannerLogo} src={Eazy2bizLogo}/>
            </div>
        );
    };

    const renderBenefit = (benefit: HomePageBenefits, index: number) => {
        return (
            <HeroComponent
                key={benefit.id}
                heading={benefit.header}
                para={benefit.description}
                link={benefit.link}
                image={benefit.image}
                imgSide={index % 2 === 0 ? "left": "right"} />
        );
    };

    return (
        <div>
            {renderMainHeader()}
            <div className={styles.promoVideo}>
                <iframe
                    width="853"
                    height="480"
                    src={`https://www.youtube.com/embed/r-4x-7E2Sfk?si=h8kDtYasqTwuU3D_`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="eazy2biz - Empowering Indian SMBs by automation and analytics"
                />
            </div>
            {homePageBenefits.map(renderBenefit)}
            {/*<div className={styles.companies_section}>*/}
            {/*    <div className={styles.companies}>*/}
            {/*        <h2 className={styles.companies_heading}>Trusted by <Span>10,000 +</Span>companies worlwide</h2>*/}
            {/*        <LineContainer>*/}
            {/*            <div style={{ width: '25%', height: '50px' }}>*/}
            {/*            yo*/}
            {/*            </div>*/}
            {/*            <div style={{ width: '25%', height: '50px' }}>*/}
            {/*            hi*/}
            {/*            </div>*/}
            {/*            <div style={{ width: '25%', height: '50px' }}>*/}
            {/*            bro*/}
            {/*            </div>*/}
            {/*            <div style={{ width: '25%', height: '50px' }}>*/}
            {/*            damn*/}
            {/*            </div>*/}
            {/*        </LineContainer>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <OptionsSection/>
            <Services/>
        </div>
    );
};

// type LineContainerProps = {
//   children: React.ReactNode;
// };
//
// const LineContainer: React.FC<LineContainerProps> = ({ children }) => {
//   return (
//     <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', marginTop: '20px' }}>
//       {children}
//     </div>
//   );
// };
