import { PricingBox, ComparisonTable } from "@eazy2biz-ui/promo-components";
import styles from './Pricing.module.css';
import {APP_CONSOLE_URL, RoutesEnum} from "@eazy2biz-ui/promo-package";
export const Pricing = () => {
  const features = [

    {
      featureName: "Mobile App",
      planInformation: {
        'Free Trial': "::Yes::",
        Basic: "::Yes::",
        Enterprise: "::Yes::"
      }
    },
    {
      featureName: "Workflows",
      planInformation: {
        'Free Trial': "2",
        Basic: "5",
        Enterprise: "Unlimited"
      }
    },
    {
      featureName: "Todos",
      planInformation: {
        'Free Trial': "15",
        Basic: "150(Active)",
        Enterprise: "Unlimited"
      }
    },
    {
      featureName: "Database (Customer table)",
      planInformation: {
        'Free Trial': "1",
        Basic: "5",
        Enterprise: "Unlimited"
      }
    },
    {
      featureName: "Users",
      planInformation: {
        'Free Trial': "3",
        Basic: "10",
        Enterprise: "Unlimited"
      }
    },
    {
      featureName: "User Departments",
      planInformation: {
        'Free Trial': "3",
        Basic: "15",
        Enterprise: "Unlimited"
      }
    },
    {
      featureName: "User Restrictions",
      planInformation: {
        'Free Trial': "::Yes::",
        Basic: "::Yes::",
        Enterprise: "::Yes::"
      }
    },
    {
      featureName: "Dashboards",
      planInformation: {
        'Free Trial': "::No::",
        Basic: "::Yes::",
        Enterprise: "::Yes::"
      }
    },
    {
      featureName: "Data retention",
      planInformation: {
        'Free Trial': "2 Months",
        Basic: "1 Year",
        Enterprise: "1 Year"
      }
    },
    {
      featureName: "Emails",
      planInformation: {
        'Free Trial': "::Yes::",
        Basic: "::Yes::",
        Enterprise: "::Yes::"
      }
    },
    {
      featureName: "Whatsapp Integration",
      planInformation: {
        'Free Trial': "::No::",
        Basic: "::Yes::",
        Enterprise: "::Yes::"
      }
    },
  ];
    return (
      <>
        <div className={styles.pricing_container}>
            <PricingBox
              planName="Free Trial"
              description="For small teams, startups, freelancers etc to experience the product."
              price=""
              /*info="Billed annually or $11.99 month-to-month."*/
              get="Get Started"
              lists={[
                'Access to Mobile App',
                '15 total number of active todos',
                '3 User departments',
              ]}
              link={APP_CONSOLE_URL}
            />
            <PricingBox
              planName="Basic"
              description="For small and medium enterprises who need to automate business processes."
              price=""
              /*info="Billed annually or $23.99 month-to-month."*/
              get="Contact Us"
              lists={[
                'Access to Dashboard',
                '150 number of active todos',
                '15 User departments',
              ]}
              link={RoutesEnum.APP_CONTACT}
            />
            <PricingBox
              planName="Enterprise"
              description="For organisations that need unlimited power for Enterprise processes"
              price=""
              /*info="Billed annually or $29.99 month-to-month."*/
              get="Contact Us"
              lists={[
                'Access to Dashboard',
                'Unlimited everything',
                'Unlimited user departments',
              ]}
              link={RoutesEnum.APP_CONTACT}
            />
          </div>
        <ComparisonTable features={features} />
      </>
    )
}
