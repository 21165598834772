import {
    CommunicationFeatureImage, DatabaseFeatureImage,
    HOMEPAGE_FEATURE_OPTIONS, IntegrationFeatureImage,
    ReportsImage,
    WorkflowFeatureImage
} from "@eazy2biz-ui/promo-package";

export interface FeatureOptionsConfig {
    id: string;
    name: string;
    header: string;
    description: string;
    image: any;
}

export const featureOptions: FeatureOptionsConfig[] = [
    {
        id: '1',
        name: HOMEPAGE_FEATURE_OPTIONS.WORKFLOW.NAME,
        header: HOMEPAGE_FEATURE_OPTIONS.WORKFLOW.HEADER,
        description: HOMEPAGE_FEATURE_OPTIONS.WORKFLOW.TEXT,
        image: WorkflowFeatureImage,
    },
    {
        id: '4',
        name: HOMEPAGE_FEATURE_OPTIONS.COMMUNICATION.NAME,
        header: HOMEPAGE_FEATURE_OPTIONS.COMMUNICATION.HEADER,
        description: HOMEPAGE_FEATURE_OPTIONS.COMMUNICATION.TEXT,
        image: CommunicationFeatureImage,
    },
    {
        id: '5',
        name: HOMEPAGE_FEATURE_OPTIONS.INTEGRATIONS.NAME,
        header: HOMEPAGE_FEATURE_OPTIONS.INTEGRATIONS.HEADER,
        description: HOMEPAGE_FEATURE_OPTIONS.INTEGRATIONS.TEXT,
        image: IntegrationFeatureImage,
    },
    {
        id: '2',
        name: HOMEPAGE_FEATURE_OPTIONS.DATABASE.NAME,
        header: HOMEPAGE_FEATURE_OPTIONS.DATABASE.HEADER,
        description: HOMEPAGE_FEATURE_OPTIONS.DATABASE.TEXT,
        image: DatabaseFeatureImage,
    },
    {
        id: '3',
        name: HOMEPAGE_FEATURE_OPTIONS.REPORTS.NAME,
        header: HOMEPAGE_FEATURE_OPTIONS.REPORTS.HEADER,
        description: HOMEPAGE_FEATURE_OPTIONS.REPORTS.TEXT,
        image: ReportsImage,
    }
]
