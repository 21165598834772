import {CustomImage, FormImage, HOMEPAGE_MASTER_BENEFITS, ReportsImage, TaskImage} from "@eazy2biz-ui/promo-package";

export interface HomePageBenefits {
    id: string;
    header: string;
    description: string;
    image: any;
    link: string;
}

export const homePageBenefits: HomePageBenefits[] = [
    {
        id: '0',
        header: HOMEPAGE_MASTER_BENEFITS.CUSTOM.HEADER,
        description: HOMEPAGE_MASTER_BENEFITS.CUSTOM.TEXT,
        image: CustomImage,
        link: '#'
    },
    {
        id: '1',
        header: HOMEPAGE_MASTER_BENEFITS.AUTOMATE.HEADER,
        description: HOMEPAGE_MASTER_BENEFITS.AUTOMATE.TEXT,
        image: FormImage,
        link: '#'
    },
    {
        id: '2',
        header: HOMEPAGE_MASTER_BENEFITS.EFFICIENCY.HEADER,
        description: HOMEPAGE_MASTER_BENEFITS.EFFICIENCY.TEXT,
        image: TaskImage,
        link: '#'
    },
    {
        id: '3',
        header: HOMEPAGE_MASTER_BENEFITS.REPORTS.HEADER,
        description: HOMEPAGE_MASTER_BENEFITS.REPORTS.TEXT,
        image: ReportsImage,
        link: '#'
    }
];
