import React from 'react';
import ReactDOM from 'react-dom';
import App from './js/App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import "bootstrap/dist/css/bootstrap.min.css";
import {CookiesProvider} from "react-cookie";
import "typeface-lato";
import {BrowserRouter} from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react'
import ReactGA from 'react-ga';

(document.getElementById('root') as HTMLElement).className = 'root';

const TRACKING_ID = "UA-254638362-1";
ReactGA.initialize(TRACKING_ID);


ReactDOM.render(
    <ChakraProvider>
        <CookiesProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </CookiesProvider>
    </ChakraProvider>,
    document.getElementById('root') as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

