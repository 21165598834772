import React from 'react';
import styles from "./SecurityPage.module.css";
import {APP_STRINGS} from "@eazy2biz-ui/promo-package";

interface CardProps {
  heading: string;
  date: string;
  lastDate: String,
  children: React.ReactNode;
}

export const SecurityPage: React.FC<CardProps> = (props) => {
  return (
    <div className={styles.securityPage}>
      <span className={styles.securityLogo}>{APP_STRINGS.PROTEGO_TECHNOLOGIES}</span>
      <div>
        <h3 className={styles.securityHead}>{props.heading}</h3>
        <p className={styles.date}>{props.date}</p><br /><br />
      </div>

      <div className={styles.para}>{props.children}</div>

      <p className={styles.date}>Last Updated:  {props.lastDate}</p>
    </div>
  );
};


