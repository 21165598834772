import React from 'react';
import styles from "./HeroComponent.module.css";

interface Props {
  heading: string;
  para: string;
  link: string;
  image: string;
  imgSide: 'left' | 'right';
}
export const HeroComponent: React.FC<Props> = ({ heading, para, link, image, imgSide }) => {
  console.log("This  = "+imgSide);
  return (
    <div className={`${styles.heroContainer} ${imgSide === 'left' ? 'left' : 'right'}`}>
      {imgSide === 'left' ? (
        <>
        <div className={styles.inlineContainer}>
          <img className={styles.imageGenericContainer} src={image} alt="description" />
          <div className={styles.textGenericContainer} style={{float:"right"}}>
            <h2>{heading}</h2>
            <p>{para}</p>
            <a href={link} className={styles.readMore}>
              Read more
            </a>
          </div>
        </div>
        </>
      ) : (
        <>
        <div className={styles.inlineContainer}>
          <div className={styles.textGenericContainer}>
            <h2>{heading}</h2>
            <p>{para}</p>
            <a href={link} className={styles.readMore}>
              Read more
            </a>
          </div>
          <img className={styles.imageGenericContainer} src={image} alt="description" />
        </div>
        </>
      )}
    </div>
  );
};
